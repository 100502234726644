import { useState, useMemo, useLayoutEffect, useEffect } from 'react';
import { MaterialReactTable, MRT_ColumnDef, MRT_Row, MRT_TableOptions, useMaterialReactTable } from 'material-react-table';
import { Edit, RemoveCircle } from '@mui/icons-material';
import { Box, Button, IconButton, Tooltip } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { UnknownAction } from '@reduxjs/toolkit';
import { TStoreStates } from '../../store';
import { fetchLocations, updateLocations } from '../../store/locations-config-actions';
import { locationsConfigActions } from '../../store/locations-config-slice';
import PopupService from '../../services/PopupService';
import { StorageFactory } from '../../services/StorageFactory';
import { Defaults, Utils, locationsColumnsConfig } from '../../helpers';
import { TLocationConfiguration } from '../../models';
import './style.scss';

const Locations = () => {
  const dispatch = useDispatch();
  const columns = useMemo<MRT_ColumnDef<TLocationConfiguration>[]>(() => locationsColumnsConfig as MRT_ColumnDef<TLocationConfiguration>[], []);
  const [tableHeight, setTableHeight] = useState(Defaults.TableHeight);
  const [changedData, setChangedData] = useState([] as TLocationConfiguration[]);
  const { items: locationsConfig } = useSelector((state: TStoreStates) => state.locationsConfig);

  useEffect(() => {
    dispatch(fetchLocations() as unknown as UnknownAction);
  }, [dispatch]);

  useLayoutEffect(() => {
    const handleResize = () => { setTableHeight(Utils.getAvailableViewportHeight(window, document)); }
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => { window.removeEventListener('resize', handleResize); };
  }, []);

  const handleSave: MRT_TableOptions<TLocationConfiguration>['onEditingRowSave'] = (data): void => {
    dispatch(locationsConfigActions.updateLocationConfig({ index: data.row.index, values: data.values }));
    setChangedData((prevState: TLocationConfiguration[]) => {
      return [...prevState, getLocationUpdatedConfiguration(data.values)]
    });
    data.table.setEditingRow(null);
  };

  const handleRemoveConfig = (row: MRT_Row<TLocationConfiguration>): void => {
    dispatch(locationsConfigActions.removeLocationConfig(row.index));
    setChangedData((prevState: TLocationConfiguration[]) => {
      return [...prevState, { location: row.original.location }]
    });
  };

  const handleSubmit = (): void => {
    const storedLocationsConfig = StorageFactory(sessionStorage).getItem('LOCATIONS_CONFIG') as unknown as TLocationConfiguration[];
    if (!storedLocationsConfig) {
      PopupService.showError('Looks like the session has expired.<br/>Please refresh the page and try again.')
    } else {
      const uniqueChangedLocations = {} as Record<string, TLocationConfiguration>;
      changedData.forEach(data => uniqueChangedLocations[data.location] = data);
      const changedLocationsConfig = Object.values<TLocationConfiguration>(uniqueChangedLocations)
        .filter(changedLocationData => hasDataChanged(storedLocationsConfig, changedLocationData));

      if (!changedLocationsConfig.length) {
        PopupService.showInfo('Looks like nothing was changed.', 'No changes found')
      } else {
        dispatch(updateLocations(changedLocationsConfig) as unknown as UnknownAction);
        setChangedData([]);
      }
    }
  };

  const hasDataChanged = (storedLocationsData: TLocationConfiguration[], { location, adyenInformation }: TLocationConfiguration): boolean => {
    const originalInformation = storedLocationsData.find(({ location: id }) => id === location)?.adyenInformation || {
      clientKey: '-',
      environment: '-',
      locale: '-',
      ecomMerchantAccount: '-',
      achMerchantAccount: '-',
      posMerchantAccount: '-'
    };
    return originalInformation?.clientKey !== adyenInformation?.clientKey
      || originalInformation?.environment !== adyenInformation?.environment
      || originalInformation?.locale !== adyenInformation?.locale
      || originalInformation?.ecomMerchantAccount !== adyenInformation?.ecomMerchantAccount
      || originalInformation?.achMerchantAccount !== adyenInformation?.achMerchantAccount
      || originalInformation?.posMerchantAccount !== adyenInformation?.posMerchantAccount
  }

  const getLocationUpdatedConfiguration = (values: Record<string, string>): TLocationConfiguration => {
    return {
      location: values.location,
      adyenInformation: {
        clientKey: values['Client Key'],
        environment: values['Environment'],
        locale: values['Locale'],
        ecomMerchantAccount: values['CC Merchant Account'],
        achMerchantAccount: values['ACH Merchant Account'],
        posMerchantAccount: values['POS Merchant Account']
      }
    }
  }

  const table = useMaterialReactTable({
    columns,
    data: locationsConfig,
    editDisplayMode: 'row',
    enableColumnActions: false,
    enableDensityToggle: false,
    enableBottomToolbar: false,
    enableEditing: true,
    enableGlobalFilter: true,
    enableStickyHeader: true,
    enablePagination: false,
    getRowId: (originalRow: any) => originalRow.location,
    muiTableBodyCellProps: { align: 'center' },
    muiTableHeadCellProps: { align: 'center' },
    muiTablePaperProps: { sx: { boxShadow: 0 } },
    muiTableProps: { sx: { tableLayout: 'fixed', boxShadow: 0 } },
    muiTableContainerProps: { sx: { maxHeight: tableHeight } },
    onEditingRowSave: handleSave,
    positionToolbarAlertBanner: 'top',
    renderRowActions: ({ row }) => (
      <Box sx={{ display: 'flex', gap: '1rem', justifyContent: 'center' }}>
        <Tooltip arrow placement='left' title='Edit Configuration'>
          <IconButton onClick={() => table.setEditingRow(row)} data-testid={`edit-location-${row.id}-btn`}>
            <Edit />
          </IconButton>
        </Tooltip>
        <Tooltip arrow placement='right' title='Remove Configuration'>
          <IconButton onClick={() => handleRemoveConfig(row)} data-testid={`remove-configuration-${row.id}-btn`}>
            <RemoveCircle />
          </IconButton>
        </Tooltip>
      </Box>
    ),
    renderTopToolbarCustomActions: () => (
      <Button className='mx-3'
        data-testid='save-configurations-btn'
        color='primary'
        variant='contained'
        disabled={!changedData.length}
        onClick={() => handleSubmit()}>Save and Deploy Configurations
      </Button>
    ),
    state: { density: 'compact' }
  });

  return (
    <div className='locations-page' data-testid='locationsConfiguration'>
      <div className='header' id='header'>
        <div className='title'>Locations Configuration</div>
        <div className='info' data-testid='total-locations'>Total: <strong>{locationsConfig.length}</strong></div>
        <div className='alert-message'>Deployment of the changed configurations<br />can take up to <span>5-10 minutes</span>.</div>
      </div>
      <MaterialReactTable table={table} />
    </div>
  );
}

export default Locations;