import axios from 'axios';
import UserService from './UserService';
import { UserAttributes } from '../models';

const CognitoService = {
  cognitoAPIUrl: 'https://9cwd6hwft6.execute-api.us-east-1.amazonaws.com/feat-cognito-service/kiosk/members',
  async authorize(key: string): Promise<void> {
    const user = await UserService.getAuthenticatedUser();
    console.log({user})
    if (user) {
      const options = {
        headers: {
          'Content-Type': String(process.env.REACT_APP_CONTENT_TYPE),
          'x-api-key': 'Il9uAQSY7v8B2EbVt5oFA2rzGT6wlcjyaxm3k8rm'
        }
      };
      const memberId = user.attributes as unknown as UserAttributes['custom:moso_member_id'];
      const response = await axios.post(`${this.cognitoAPIUrl}/${memberId}/authorize`, {
        userPoolId: user.pool.userPoolId,
        idToken: user.signInUserSession.idToken.jwtToken,
        encryptedKey: key
      }, options);
      return response.data;
    }
  }
};

export default CognitoService;