import { Html5QrcodeScanner } from 'html5-qrcode';
import { useEffect, useState } from 'react';
import CognitoService from '../services/CognitoService';


const Authorize = () => {
    const [scanResult, setScanResult] = useState(null);

    useEffect(() => {
        const scanner = new Html5QrcodeScanner('reader', {
            qrbox: {
                width:200,
                height: 200
            },
            fps: 2,
        }, false)
    
        scanner.render(success, error);
    
        async function success(result: any) {
            scanner.clear();
            
            setScanResult(result);
            await CognitoService.authorize(result);
        }
        function error(err: any) {
            console.warn(err);
        }
    }, []);


    const css = `
    .scanner {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }
    #reader {
        width:300px;    
    }
`

    return (
        <div>
            <style>{css}</style>
        <div className="scanner">
            
            <h1>QR Code Scanning</h1>
            {scanResult
                ? <div>Success: {scanResult}</div>
                : <div id="reader"></div>
            }
        </div>
        </div>
    )
}

export default Authorize;